<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">用户管理</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <div class="u-container">
      <div class="u-container-item" style="padding-right: 15px">
        <div>修改用户信息</div>
        <div class="form-item" style="margin-top: 20px">
          <v-text-field
		          :value="user.username"
		          dense
		          disabled
		          label="用户名"
		          readonly
		          outlined
          >
          </v-text-field>
        </div>
        <div class="form-item">
	        <v-text-field v-model="user.telephone" dense label="手机号" outlined>
	        </v-text-field>
        </div>
        <div class="form-item">
	        <v-text-field v-model="user.email" dense label="邮箱" outlined>
	        </v-text-field>
        </div>
        <div class="form-item">
          <v-text-field
		          v-model="user.organization"
		          dense
		          label="公司"
		          outlined
          >
          </v-text-field>
        </div>

        <v-btn color="primary" @click="saveBasicInfo()">确定修改</v-btn>
      </div>
      <div class="u-container-item" style="padding-left: 15px">
        <div>修改密码</div>
        <!-- <div class="form-item" style="margin-top: 20px">
          <div>原始密码</div>
          <v-text-field type="password" outlined dense v-model="old_password">
          </v-text-field>
        </div> -->
        <div class="form-item" style="margin-top: 20px">
	        <v-text-field v-model="new_password1" dense label="新密码" outlined type="password">
	        </v-text-field>
        </div>
        <div class="form-item">
	        <v-text-field v-model="new_password2" dense label="确认新密码" outlined type="password">
	        </v-text-field>
        </div>

        <v-btn color="primary">确定修改</v-btn>
      </div>
    </div>
  </v-container>
</template>
<script>
import { updateUserBasicInfo } from "@/api/users";

export default {
  name: "userHome",
  props: {
    id: [String, Number],
  },
  data: () => ({
    old_password: "",
    new_password1: "",
    new_password2: "",
    user: JSON.parse(localStorage.getItem("user")),
  }),
  methods: {
    saveBasicInfo() {
      let data = { ...this.user };
      console.log("data", data);
      updateUserBasicInfo(data);
    },
    changePassword() {},
  },
  mounted() {},
};
</script>
<style lang="stylus">
.n-title
	font-size 32px

.u-container
	display flex

	.u-container-item
		flex 1

		.form-item
			margin-top: -10px
</style>
